export enum EModalNames {
  Enter = 'Enter',
  Info = 'Info',
  Cms = 'Cms'
}

export enum EAuthModalSteps {
  Enter = 'Enter',
  Register = 'Register',
  RegisterConfirmation = 'RegisterConfirmation',
  RecoveryPassword = 'RecoveryPassword',
  RecoveryPasswordConfirmation = 'RecoveryPasswordConfirmation',
}
