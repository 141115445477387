import React from "react";

import PhoneInput, { PhoneInputProps } from "react-phone-input-2";
import ru from "react-phone-input-2/lang/ru.json";
import "react-phone-input-2/lib/style.css";

import Button, { ButtonView, ButtonSize } from "../Button";

import * as Styles from "./Form.styled";

type TFormProps = {
  onSubmit: () => void;
};

type TPhoneCountry = {
  countryCode: string;
  dialCode: string;
  format: string;
  iso2: string;
  name: string;
  priority: number;
  regions: string[];
};

type InputProps = {
  name: string;
  register: any;
  type?: string;
  disabled?: boolean;
  placeholder?: string;
  rules?: Object;
};

type TextAreaProps = {
  name: string;
  register: any;
  disabled?: boolean;
  placeholder?: string;
  rules?: Object;
};

type TFormField = {
  className?: string;
  isError?: boolean;
};

type TAgreeProps = {
  register: any;
  name: string;
  color?: LabelAgreeColor;
  rules?: Object;
  className?: string;
  onChange:(value:boolean)=>void;
};

type TAgreeButton = {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

type ActionButton = {
  onClick?: (evt: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
};

type TPhoneInputProps = Omit<PhoneInputProps, "onChange" | "value"> & {
  value: {
    number: string;
    country: string;
  };

  onChange: (...event: any[]) => void;
  isError?: boolean;
};

type TRecaptcha = {
  sitekey: string | null;
  size?: "invisible | compact | normal";
};

type TForm<P = {}> = React.FC<P> & {
  Label: React.FC;
  Field: React.FC<TFormField>;
  Input: React.FC<InputProps>;
  Textarea: React.FC<TextAreaProps>;
  Error: React.FC;
  PhoneInput: React.FC<TPhoneInputProps>;
  Agree: React.FC<TAgreeProps>;
  AgreeButton: React.FC<TAgreeButton>;
  SubmitButton: React.FC<ActionButton>;
  CancelButton: React.FC<ActionButton>;
  Recaptcha: React.FC<TRecaptcha>;
};

const Form: TForm<TFormProps> = ({ children, onSubmit }) => {
  return <Styles.Form onSubmit={onSubmit}>{children}</Styles.Form>;
};

Form.Error = ({ children }) => <Styles.TextError>{children}</Styles.TextError>;
Form.Label = ({ children }) => <Styles.Label>{children}</Styles.Label>;
Form.Field = ({ children, className, isError = false }) => (
  <Styles.Field className={className} isError={isError}>
    {children}
  </Styles.Field>
);
Form.Input = ({
  name,
  type = "text",
  placeholder,
  disabled = false,
  register,
  rules,
  children,
}) => (
  <Styles.Input
    {...register(name, rules)}
    type={type}
    placeholder={placeholder}
    disabled={disabled}
  >
    {children}
  </Styles.Input>
);

Form.Textarea = ({
  name,
  placeholder,
  disabled,
  register,
  rules,
  children,
}) => (
  <Styles.Textarea
    {...register(name, rules)}
    placeholder={placeholder}
    disabled={disabled}
  >
    {children}
  </Styles.Textarea>
);

Form.PhoneInput = ({
  value,
  onChange,
  inputProps,
  placeholder,
  country = "ru",
  onlyCountries = [],
  masks = {},
  isError = false,
}) => {
  const handlerOnChange = (value: string, country: TPhoneCountry) => {
    onChange({ number: value, country });
  };

  return (
    <Styles.PhoneInput isError={isError}>
      <PhoneInput
        country={country}
        value={value.number}
        onChange={handlerOnChange}
        inputProps={inputProps}
        placeholder={placeholder}
        onlyCountries={onlyCountries}
        masks={masks}
        localization={ru}
      />
    </Styles.PhoneInput>
  );
};

export enum LabelAgreeColor {
  Default = "Default",
  Contrast = "Contrast",
}

Form.Agree = ({
  name,
  register,
  rules,
  children,
  color = LabelAgreeColor.Default,
  className = "",
  onChange,
}) => {
  return (
    <Styles.LabelAgree color={color} className={className}>
      <Styles.Checkbox {...register(name, rules)} onChange={(e)=>{onChange(e.target.checked)}}/>
      <Styles.CheckboxIndicator>
        <svg
          width="12"
          height="10"
          viewBox="0 0 12 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.2002 4.74896L4.6911 7.87292L10.8002 2.2002"
            stroke="white"
            stroke-width="2"
          />
        </svg>
      </Styles.CheckboxIndicator>
      <Styles.AgreeContent>{children}</Styles.AgreeContent>
    </Styles.LabelAgree>
  );
};

Form.AgreeButton = ({ children, onClick }) => {
  return (
    <Styles.AgreeButton onClick={onClick} type="button">
      {children}
    </Styles.AgreeButton>
  );
};

Form.SubmitButton = ({ onClick, children, disabled = false }) => {
  return (
    <Button
      onClick={onClick}
      type="submit"
      disabled={disabled}
      size={ButtonSize.Small}
    >
      {children}
    </Button>
  );
};

Form.CancelButton = ({ onClick, children }) => {
  return (
    <Button
      onClick={onClick}
      type="button"
      view={ButtonView.Outline}
      size={ButtonSize.Small}
    >
      {children}
    </Button>
  );
};

Form.Recaptcha = ({ sitekey, size = "invisible" }) => {
  return <div data-sitekey={sitekey} data-size={size}></div>;
};

export default Form;
