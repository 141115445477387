import React from 'react'

import EnterModal from './Enter'
import InfoModal from './Info'
import CmsModal from './cmsData/index'

const Modals = () => {
  return <>
    <EnterModal />
    <InfoModal />
    <CmsModal />
  </>
}

export default Modals
