import storeApp from "../../store/AppStore";
import "./style.scss";
import classNames from "classnames";

export default function Preloader({
  text,
  className,
}: {
  text?: string;
  className?: string;
}) {
  return (
    <div
      className={classNames(`preloader__wrapper ${className}`, {
         "preloader__overlay": storeApp.isLoading,
      })}
    >
      <div className="preloader"></div>
      {text && <p className="preloader__text-slogan">{text}</p>}
    </div>
  );
}
