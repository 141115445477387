import styled from 'styled-components'

export const Container = styled.div<{ isContent: boolean }>`  
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
  }

  display:flex;
  flex-direction:column;
  max-height:85vh;

`

export const Content = styled.div`
  overflow-y:auto;
  margin-bottom: 15px;
  color: ${props => props.theme.colors.text.contrast};
  
  & span {
    font-family: ${props => props.theme.fonts.main};
    font-size: 12px !important;
    line-height: 16px !important;
    color: ${props => props.theme.colors.text.contrast} !important;
    
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
      font-size: 14px !important;
      line-height: 18px !important;
    }
  }
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    margin-bottom: 30px;
  }
`

export const Action = styled.div`
  display: flex;
  justify-content: center;
  
  & button {
     @media ${props => props.theme.maxDevice['desktop']},
     @media ${props => props.theme.maxDevice['desktop-medium']} {
      width: 100%;
      justify-content: center;
    }
  
    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
      padding-top: 14px;
      padding-bottom: 14px;
      font-size: 14px;
      line-height: 18px;
    }
  }
`

export const Header = styled.div`
  margin-bottom: 30px;
`

export const Slogan = styled.h3`
  margin: 0;
  padding: 0;
  font-weight: 500;
  font-size: 19px;
  line-height: 24px;
  text-align: center;
  color: ${props => props.theme.colors.text.contrast};

    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    font-size: 26px;
    line-height: 33px;
  }
`

export const Title = styled.p`
  margin: 0;
  margin-top: 20px;
  padding: 0;
  font-size: 12px;
  line-height: 15px;
  text-align: center;

    @media ${props => props.theme.device['desktop']},
    @media ${props => props.theme.device['desktop-medium']} {
    font-size: 16px;
    line-height: 20px;
  }
`
