import React from 'react'

import { observer } from 'mobx-react-lite'

import RecoveryPasswordConfirmation, { IRecoveryPasswordConfirmationSubmitData } from '../../../common/Forms/RecoveryPasswordConfirmation'
import storeApp from '../../../../store/AppStore'
import { EAuthModalSteps } from '../../../Modals/Modals.type'

const RecoveryPasswordConfirmationForm = () => {
  const onSubmit = (data: IRecoveryPasswordConfirmationSubmitData) => {
    storeApp.authRecoveryPasswordConfirm(data)
  }

  const onClickSwitchingRecoveryPassword = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    storeApp.setAuthModalData(EAuthModalSteps.RecoveryPassword)
  }

  const backendErrors = React.useMemo(() => {
    return storeApp.errors;
  }, [storeApp.errorsUpdated]);

  return <RecoveryPasswordConfirmation
    cbSubmitAction={ onSubmit }
    cbCancelAction={ onClickSwitchingRecoveryPassword }
    backendErrors={ backendErrors }
    recaptcha2={storeApp.recaptcha.key2}
    cbRecaptchaToken={(token) => storeApp.setRecaptcha2Token(token, 'password_reset_confirm')}
  />
}

export default observer(RecoveryPasswordConfirmationForm)

