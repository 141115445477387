import styled, { css } from "styled-components";
import ButtonLibrary from "../../common/Button";
import { isSafari } from "react-device-detect";
import Question from "../../../svg/social/questionmin.svg";
import QuestionMob from "../../../svg/social/qustionmob.svg";

export const Wrapper = styled.div`
  position: relative;
  background: radial-gradient(
      26.63% 49.16% at 98.16% 94.93%,
      rgba(255, 227, 154, 0.21) 0%,
      rgba(249, 202, 82, 0) 100%
    ),
    radial-gradient(
      38.44% 59.09% at 76.15% 55.13%,
      rgba(163, 92, 220, 0.45) 0%,
      rgba(163, 92, 220, 0) 100%
    ),
    radial-gradient(
      24.44% 62.58% at 38.82% 66.91%,
      rgba(22, 53, 215, 0.3) 0%,
      rgba(22, 53, 215, 0) 100%
    ),
    radial-gradient(
      50.64% 67.16% at 63.02% 20.2%,
      rgba(163, 146, 243, 0.2) 0%,
      rgba(147, 129, 220, 0) 100%
    ),
    radial-gradient(
      57.2% 67.63% at 33.13% 18.68%,
      rgba(72, 253, 253, 0.45) 0%,
      rgba(72, 242, 253, 0) 100%
    ),
    #171133;

  @media ${(props) => props.theme.maxDevice["desktop"]},
    @media ${(props) => props.theme.maxDevice["desktop-medium"]} {
    padding-top: 55px;
  }

  &::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 350px;
    background: linear-gradient(180deg, rgba(23, 17, 51, 0) 0%, #171133 100%);
    z-index: 0;
  }
`;

export const Root = styled.div`
  position: relative;
  z-index: 5;
  display: flex;
  flex-direction: column;

  @media ${(props) => props.theme.maxDevice["desktop"]},
    @media ${(props) => props.theme.maxDevice["desktop-medium"]} {
    margin-bottom: 35px;
  }
  @media ${(props) => props.theme.device["desktop"]},
    @media ${(props) => props.theme.device["desktop-medium"]} {
    flex-direction: row;
    align-items: center;
  }
`;

export const Info = styled.div`
  @media ${(props) => props.theme.device["desktop"]},
    @media ${(props) => props.theme.device["desktop-medium"]} {
    width: 465px;
    flex-shrink: 0;
    margin-right: 50px;
  }
`;

export const Marking = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 18px;
  
`;
export const Ord = styled.div`
  width: 48px;
  height: 35px;
  font-size: 10px;
  line-height: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px 0px 0px 20px;
  background-color: rgba(33, 33, 33, 1);
  color: #ffffff;
  cursor: default;
  font-weight: 700;

  @media ${(props) => props.theme.device["desktop"]},
    @media ${(props) => props.theme.device["desktop-medium"]} {
    font-size: 18px;
    line-height: 22px;
    width: 88px;
    height: 44px;
  }
`;
export const Text = styled.div`
  // position: relative;
  width: 100%;
  height: 35px;
  font-size: 11px;
  line-height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 20px 20px 0px;
  background: linear-gradient(149deg, #FFE324 5.7%, #FFB533 93.88%);
  color: #000000;
  cursor: default;
  font-weight: 400;

  @media ${(props) => props.theme.device["desktop"]},
    @media ${(props) => props.theme.device["desktop-medium"]} {
    width: 365px;
    height: 44px;
    font-size: 16px;
    line-height: 20px;
  }
`;
export const Svg = styled.svg`
  background-image: url(${QuestionMob});
  background-repeat: no-repeat;
  width: 12px;
  height: 12px;
  margin-left: 5px;
  @media ${(props) => props.theme.device["desktop"]},
  @media ${(props) => props.theme.device["desktop-medium"]} {
    background-image: url(${Question});
    background-repeat: no-repeat;
    width: 16px;
    height: 16px;
    margin-left: 10px;
}
`;

export const Tool = styled.p`
 
 font-weight: 400;
 font-size: 14px;
 line-height: 18px;
`;

export const Slogan = styled.h2`
  margin: 0;
  margin-bottom: 20px;
  padding: 0;
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  color: ${(props) => props.theme.colors.text.contrast};
  @media ${(props) => props.theme.device["desktop"]},
    @media ${(props) => props.theme.device["desktop-medium"]} {
    margin-bottom: 30px;
    font-size: 32px;
    line-height: 40px;
  }
`;

export const Title = styled.p`
  margin: 0;
  margin-bottom: 20px;
  padding: 0;
  @media ${(props) => props.theme.device["desktop"]},
    @media ${(props) => props.theme.device["desktop-medium"]} {
    margin-bottom: 30px;
  }
`;

export const Actions = styled.div<{ isReverse: boolean }>`
  display: flex;

  ${(props) =>
    props.isReverse &&
    css`
      flex-direction: row-reverse;
      justify-content: flex-end;
    `};

  & > div {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }

    ${(props) =>
      props.isReverse &&
      css`
        &:first-child {
          margin-right: 0;

          &:last-child {
            margin-right: 10px;
          }
        }
      `};

    @media ${(props) => props.theme.maxDevice["desktop"]},
      @media ${(props) => props.theme.maxDevice["desktop-medium"]} {
      width: 100%;
    }
    @media ${(props) => props.theme.device["desktop"]},
      @media ${(props) => props.theme.device["desktop-medium"]} {
      margin-right: 30px;

      ${(props) =>
        props.isReverse &&
        css`
          &:last-child {
            margin-right: 30px;
          }
        `}
    }
  }

  & > a {
    @media ${(props) => props.theme.maxDevice["desktop"]},
      @media ${(props) => props.theme.maxDevice["desktop-medium"]} {
      margin-right: 10px;

      &:last-child {
        margin-right: 0;
      }

      ${(props) =>
        props.isReverse &&
        css`
          &:first-child {
            margin-right: 0;
          }

          &:last-child {
            margin-right: 10px;
          }
        `}
    }
  }
`;

export const Button = styled(ButtonLibrary)`
  @media ${(props) => props.theme.maxDevice["desktop"]},
    @media ${(props) => props.theme.maxDevice["desktop-medium"]} {
    box-sizing: border-box;
    width: 100%;
    justify-content: center;
  }
`;

export const Background = styled.div`
  @media ${(props) => props.theme.maxDevice["desktop"]},
    @media ${(props) => props.theme.maxDevice["desktop-medium"]} {
    display: flex;
    justify-content: center;
    order: -1;
    margin-bottom: 30px;
  }
  @media ${(props) => props.theme.device["desktop"]},
    @media ${(props) => props.theme.device["desktop-medium"]} {
    flex-grow: 1;
  }

  & > svg {
    width: 100%;
    height: max-content;

    ${isSafari &&
    css`
      @media ${(props) => props.theme.maxDevice["desktop"]},
        @media ${(props) => props.theme.maxDevice["desktop-medium"]} {
        height: intrinsic;
      }
    `}

    @media ${(props) => props.theme.device["desktop"]},
    @media ${(props) => props.theme.device["desktop-medium"]} {
      height: auto;
    }
  }
`;

export const Image = styled.img`
  max-width: 100%;
  object-fit: cover;
`;

export const Down = styled.div`
  position: relative;
  z-index: 5;
`;
