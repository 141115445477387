import { INavigations, ISocials } from '../../components/common/types'
import { SectionNamesRK } from './sections/names'

export const navigations: INavigations = [
  {
    id: 1,
    name: 'Welcome',
    href: SectionNamesRK.Welcome,
  },
  {
    id: 2,
    name: 'Возможности',
    href: SectionNamesRK.Capabilities,
  },
  {
    id: 3,
    name: 'Компетенции',
    href: SectionNamesRK.Competencies,
  },
  {
    id: 4,
    name: 'Инвентарь',
    href: SectionNamesRK.Inventory,
  },
  {
    id: 5,
    name: 'Преимущества',
    href: SectionNamesRK.Benefits,
  },
  {
    id: 6,
    name: 'Контакты',
    href: SectionNamesRK.Contacts,
  },
]

export const socials: ISocials = [
  {
    id: 1,
    name: 'Telegram',
    systemName: 'tg',
    href: 'https://t.me/bidfox_news',
  },
  {
    id: 2,
    name: 'ВКонтакте',
    systemName: 'vk',
    href: 'https://vk.com/bidfoxmarket',
  }
]
