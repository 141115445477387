import React, { useEffect, useRef } from "react";

import Form from "../../Form";

import { useForm } from "react-hook-form";

import * as Styles from "./RecoveryPasswordConfirmation.styled";
import { requiredValidation } from "../../Form/utils/validation";
import storeApp, { TErrors, TRecaptcha } from "../../../../store/AppStore";
import Preloader from "../../../preloader";
import { observer } from "mobx-react-lite";

export interface IRecoveryPasswordConfirmationSubmitData {
  code: string;
  password: string;
}

interface IRecoveryPasswordConfirmationFormProps {
  cbSubmitAction: (data: IRecoveryPasswordConfirmationSubmitData) => void;
  cbCancelAction?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  backendErrors?: TErrors;
  recaptcha2?: string | null;
  cbRecaptchaToken: (token: string) => void;
}

interface IRecoveryPasswordConfirmationFormInitialInputs {
  code: string;
  password: string;
}

enum RecoveryPasswordConfirmationFormInputNames {
  code = "code",
  password = "password",
}

const RecoveryPasswordConfirmation: React.FC<
  IRecoveryPasswordConfirmationFormProps
> = ({
  cbCancelAction,
  cbSubmitAction,
  backendErrors,
  recaptcha2 = null,
  cbRecaptchaToken = () => {},
}) => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<IRecoveryPasswordConfirmationFormInitialInputs>({
    mode: "onChange",
    defaultValues: {
      [RecoveryPasswordConfirmationFormInputNames.code]: "",
      [RecoveryPasswordConfirmationFormInputNames.password]: "",
    },
  });

  useEffect(() => {
    if (backendErrors) {
      const errors = Object.entries(backendErrors) as [
        [keyof typeof RecoveryPasswordConfirmationFormInputNames, string]
      ];
      errors.forEach(([name, value]) => {
        if (value) {
          setError(name, {
            type: "backend",
            message: value,
          });
        }
      });
    }
  }, [backendErrors]);

  const recaptchaRef = useRef(null);

  useEffect(() => {
    if (recaptcha2) {
      window.grecaptcha.render(recaptchaRef.current, {
        sitekey: recaptcha2,
        callback: (response: any) => {
          cbRecaptchaToken(response);
        },
      });
    }
  }, [recaptcha2]);

  return (
    <Form onSubmit={handleSubmit(cbSubmitAction)}>
      {storeApp.isLoading && (
        <Preloader
          className="form-enter__preloader--top-20"
          text={"Загрузка"}
        />
      )}
      {!storeApp.isLoading && (
        <>
          <Form.Field
            isError={Boolean(
              errors[RecoveryPasswordConfirmationFormInputNames.code]?.message
            )}
          >
            <Form.Input
              name={RecoveryPasswordConfirmationFormInputNames.code}
              register={register}
              placeholder="Введите код из SMS"
              rules={requiredValidation}
            />
            <Form.Label>Введите код из SMS</Form.Label>
            {errors &&
              errors[RecoveryPasswordConfirmationFormInputNames.code] && (
                <Form.Error>
                  {
                    errors[RecoveryPasswordConfirmationFormInputNames.code]
                      ?.message
                  }
                </Form.Error>
              )}
          </Form.Field>
          <Form.Field
            isError={Boolean(
              errors[RecoveryPasswordConfirmationFormInputNames.password]
                ?.message
            )}
          >
            <Form.Input
              name={RecoveryPasswordConfirmationFormInputNames.password}
              register={register}
              placeholder="Новый пароль"
              rules={requiredValidation}
              type="password"
            />
            <Form.Label>Новый пароль</Form.Label>
            {errors &&
              errors[RecoveryPasswordConfirmationFormInputNames.password] && (
                <Form.Error>
                  {
                    errors[RecoveryPasswordConfirmationFormInputNames.password]
                      ?.message
                  }
                </Form.Error>
              )}
          </Form.Field>
        </>
      )}
      <Form.Field>
        <div ref={recaptchaRef}></div>
      </Form.Field>
      <Form.Field>
        <Styles.Buttons>
          <Form.SubmitButton>Подтвердить</Form.SubmitButton>
          <Form.CancelButton onClick={cbCancelAction}>Назад</Form.CancelButton>
        </Styles.Buttons>
      </Form.Field>
    </Form>
  );
};

export default observer(RecoveryPasswordConfirmation);
