import React from "react";

import * as Styles from "./Legal.styled";

import Section from "../../common/Section";

import SectionParallax, { ParallaxSide } from "../SectionParallax";
import { useDesktop } from "../../../styled/useMedia";
import { ModalView } from "../../common/Modal";

import storeApp from "../../../store/AppStore";
import { EModalNames } from "../../Modals/Modals.type";

const LegalSection = () => {
  const isDesktop = useDesktop();

  const bgImageDesktopStyleObject = {
    left: "60%",
    width: "75%",
    height: "100%",
  };

  const onClickPersonalData = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    storeApp.modalOpen(EModalNames.Cms);
    storeApp.setInfoModalData({
      slogan: storeApp.agreementPD.title,
      buttonTitle: "Закрыть",
      content: storeApp.agreementPD.content,
      view: ModalView.Fixed,
      cbOnClickClose: () => {
        storeApp.resetInfoModalData();
        storeApp.modalClose(EModalNames.Cms);
      },
    });
  };
  const onClickPrivacyPolicy = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    storeApp.modalOpen(EModalNames.Cms);
    storeApp.setInfoModalData({
      slogan: storeApp.privacyPolicy.title,
      buttonTitle: "Закрыть",
      content: storeApp.privacyPolicy.content,
      view: ModalView.Fixed,
      cbOnClickClose: () => {
        storeApp.resetInfoModalData();
        storeApp.modalClose(EModalNames.Cms);
      },
    });
  };
  const onClickAdvertiserContract = (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    storeApp.modalOpen(EModalNames.Cms);
    storeApp.setInfoModalData({
      slogan: storeApp.advertiserContract.title,
      buttonTitle: "Закрыть",
      content: storeApp.advertiserContract.content,
      view: ModalView.Fixed,
      cbOnClickClose: () => {
        storeApp.resetInfoModalData();
        storeApp.modalClose(EModalNames.Cms);
      },
    });
  };
  const onClickOwnerContract = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    storeApp.modalOpen(EModalNames.Cms);
    storeApp.setInfoModalData({
      slogan: storeApp.ownerContract.title,
      buttonTitle: "Закрыть",
      content: storeApp.ownerContract.content,
      view: ModalView.Fixed,
      cbOnClickClose: () => {
        storeApp.resetInfoModalData();
        storeApp.modalClose(EModalNames.Cms);
      },
    });
  };
  const onClickAdvertiserPriceList = (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    storeApp.modalOpen(EModalNames.Cms);
    storeApp.setInfoModalData({
      slogan: storeApp.advertiserPriceList.title,
      buttonTitle: "Закрыть",
      content: storeApp.advertiserPriceList.content,
      view: ModalView.Fixed,
      cbOnClickClose: () => {
        storeApp.resetInfoModalData();
        storeApp.modalClose(EModalNames.Cms);
      },
    });
  };
  const onClickOwnerPriceList = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    storeApp.modalOpen(EModalNames.Cms);
    storeApp.setInfoModalData({
      slogan: storeApp.ownerPriceList.title,
      buttonTitle: "Закрыть",
      content: storeApp.ownerPriceList.content,
      view: ModalView.Fixed,
      cbOnClickClose: () => {
        storeApp.resetInfoModalData();
        storeApp.modalClose(EModalNames.Cms);
      },
    });
  };
  
  const onClickSoftwareDocumentation = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    storeApp.modalOpen(EModalNames.Cms);
    storeApp.setInfoModalData({
      slogan: storeApp.softwareDocumentation.title,
      buttonTitle: "Закрыть",
      content: storeApp.softwareDocumentation.content,
      view: ModalView.Fixed,
      cbOnClickClose: () => {
        storeApp.resetInfoModalData();
        storeApp.modalClose(EModalNames.Cms);
      },
    });
  };

  return (
    <Styles.Wrapper>
      <SectionParallax
        side={ParallaxSide.Right}
        bgImageStyle={isDesktop ? bgImageDesktopStyleObject : {}}
      >
        <Section>
          <Styles.Header>
            <Styles.Slogan>Правовая информация</Styles.Slogan>
          </Styles.Header>
          <Styles.Content>
            <Styles.P>
              Сайт www.bidfox.ru принадлежит Обществу с ограниченной
              ответственностью «Бидфокс» (ОГРН 1167746745320, ИНН 7703414576, г.
              Москва, ул. Староалексеевская, д. 5).
            </Styles.P>
            <Styles.P>
              Компания является администратором доменного имени и рассматривает
              Сайт в качестве объекта имущественных прав в значении, придаваемом
              этому термину Федеральным законом от 27.07.2006 № 149-ФЗ «Об
              информации, информационных технологиях и о защите информации».
            </Styles.P>
            <Styles.P>
              Используя данный сайт, Вы соглашаетесь с условиями, указанными
              ниже.
            </Styles.P>
            <Styles.P>
              Условия взаимодействия сервиса BidFox с рекламодателями определены{" "}
              <Styles.StyledButton onClick={onClickAdvertiserContract}>
                в оферте
              </Styles.StyledButton>
              . Акцепт оферты происходит в момент регистрации пользователя.
            </Styles.P>
            <Styles.P>
              Условия взаимодействия сервиса BidFox с администраторами сообществ
              определены{" "}
              <Styles.StyledButton onClick={onClickOwnerContract}>
                в оферте
              </Styles.StyledButton>
              . Акцепт оферты происходит в момент регистрации пользователя.
            </Styles.P>
            <Styles.P>
              Информация о{" "}
              <Styles.StyledButton onClick={onClickAdvertiserPriceList}>
                стоимости услуг
              </Styles.StyledButton>{" "}
              сервиса BidFox для рекламодателей.
            </Styles.P>
            <Styles.P>
              Информация о{" "}
              <Styles.StyledButton onClick={onClickOwnerPriceList}>
                стоимости услуг
              </Styles.StyledButton>{" "}
              сервиса BidFox для администраторов сообществ.
            </Styles.P>
            <Styles.P>
              <Styles.StyledButton onClick={onClickPersonalData}>
                Политика по обработке персональных данных.
              </Styles.StyledButton>
            </Styles.P>
            <Styles.P>
              <Styles.StyledButton onClick={onClickPrivacyPolicy}>
                Политика конфиденциальности.
              </Styles.StyledButton>
            </Styles.P>
            <Styles.P>
              <Styles.StyledButton onClick={onClickSoftwareDocumentation}>
                Документация
              </Styles.StyledButton>
              , содержащая описание функциональных характеристик программного
              обеспечения BidFox и информацию, необходимую для установки и
              эксплуатации программного обеспечения BidFox.
            </Styles.P>
            <Styles.P>
              Доступ к сервису BidFox предоставляется пользователям без
              ограничений на всей территории Российской Федерации, включая
              Республику Крым, ДНР, ЛНР, Херсонскую и Запорожскую области.
            </Styles.P>
          </Styles.Content>
        </Section>
      </SectionParallax>
    </Styles.Wrapper>
  );
};

export default LegalSection;
