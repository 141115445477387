import React from 'react'

import Modal from '../../common/Modal'
import Button from '../../common/Button'

import storeApp from '../../../store/AppStore'
import { observer } from 'mobx-react-lite'
import { EModalNames } from '../Modals.type'

import * as Styles from './cmsData.styled'

const CmsModal: React.FC = () => {
  const onClickCloseModal = () => {
    storeApp.modalClose(EModalNames.Cms)
    storeApp.resetInfoModalData()
  }

  const onClickClose = storeApp.infoModalData.cbOnClickClose || onClickCloseModal

  return <Modal
    view={ storeApp.infoModalData.view }
    isOpen={ storeApp.modalOpenNames.includes(EModalNames.Cms) }
    setIsClose={ onClickCloseModal }
  >
    <Styles.Container isContent={ Boolean(storeApp.infoModalData.content) }>
    {(storeApp.infoModalData.slogan || storeApp.infoModalData.title) && (
            <Styles.Header>
              {storeApp.infoModalData.slogan && <Styles.Slogan>{storeApp.infoModalData.slogan}</Styles.Slogan>}
              {storeApp.infoModalData.title && <Styles.Title>{storeApp.infoModalData.title}</Styles.Title>}
            </Styles.Header>
          )}
      {
        storeApp.infoModalData.content &&
        <Styles.Content dangerouslySetInnerHTML={ {__html: storeApp.infoModalData.content } } />
      }
      {
        storeApp.infoModalData.buttonTitle &&
          <Styles.Action>
            <Button onClick={ onClickClose }>{ storeApp.infoModalData.buttonTitle }</Button>
          </Styles.Action>
      }
    </Styles.Container>
  </Modal>
}

export default observer(CmsModal)
