import { makeAutoObservable } from "mobx";
import { toast } from "react-toastify";
import { IEnterSubmitData } from "../components/common/Forms/Enter";
import { IRecoveryPasswordSubmitData } from "../components/common/Forms/RecoveryPassword";
import { IRecoveryPasswordConfirmationSubmitData } from "../components/common/Forms/RecoveryPasswordConfirmation";
import { IRegisterSubmitData } from "../components/common/Forms/Register";
import { IRegisterConfirmationSubmitData } from "../components/common/Forms/RegisterConfirmation";
import { EAuthModalSteps, EModalNames } from "../components/Modals/Modals.type";
import { ModalView } from "../components/common/Modal";
import { IFeedbackFormInitialInputs } from "../components/common/Forms/Feedback";

// FOR TEST
// phone +79129876543
// pass 111111
//-------------
// phone +79128765432
// pass 111111
let API_URL: string =
  (process.env.REACT_APP_PERSONAL_AREA === "owner"
    ? process.env.REACT_APP_API_URL_OWNER
    : process.env.REACT_APP_API_URL_ADV) || "///";
API_URL = API_URL.substring(0, API_URL.length - 1);
const PAGE_TYPE = process.env.REACT_APP_PERSONAL_AREA;

const ERROR_MESSAGES = {
  server:
    "Возникла ошибка на сервере. Вероятно, мы об этом уже знаем. Но будет круто, если ты дополнительно нам расскажешь о месте и времени возникновения ошибки. Контакты внизу страницы. Спасибо!",
  fieldsCommonError:
    "Мы подсветили поля формы, где могла быть допущена ошибка.",
  fieldDefaultError: "Введены некорректные данные.",
  fieldIsRequeredError: "Введены некорректные данные.",
  isRequeredError: "Заполните обязательные поля",
  fieldTOEError:
    "Введите email или номер телефона с кодом страны в формате +79123456789",
};

interface ILoginData {
  username: string;
  password: string;
  isRemember: boolean;
}

interface IAuthModalData {
  stepName: EAuthModalSteps | "";
}

interface IInfoModalData {
  slogan: string;
  title?: string;
  content?: string;
  view?: ModalView;
  buttonTitle?: string;
  cbOnClickClose?: (() => void) | undefined;
}

interface ICountry {
  code: string; // ru, ua,...
  id: number;
  image: string;
  is_deleted: number;
  mask: string;
  maskPrefix: string;
  maskRegex: string;
  mcc: string;
  name: string;
  registration: number;
}

export type TRecaptcha = {
  key2: string | null;
  key3: string | null;
  url: string;
  tokenPwdReset: string | null;
  tokenPwdResetConfirm: string | null;
  tokenSignup: string | null;
  tokenFeedback: string | null;
  tokenEnter: string | null;
  type: number;
  isShowRecaptchaPwdReset: boolean;
  isShowRecaptchaSignup: boolean;
  isShowRecaptchaFeedback: boolean;
  isShowRecaptchaEnter: boolean;
};

type TSettings = {
  version: number;
  settings: {
    timezone: string; //"Europe/Moscow"
    main_info: {
      email: string; // "info@bidfox.ru",
      phone: string; //"8 800 777 53 14"
    };
    recaptcha?: TRecaptcha | null;
  };
  urls: {
    publicPageOwner: string;
    advertiser: string;
  };
  video: {
    presentation: string;
    instruction: string;
  };
};

type TErrorResBody = {
  field: string;
  message: string;
};

type TEnterFormDefaultData = {
  phone: string;
  pass: string;
};

type TSuccessLogin = {
  access_token: string;
  expiration_time: string;
  expired_in: string;
  refresh_token: string;
};

type TSignup = {
  password: string;
  phone: number;
  email: string;
  phoneDirty: number;
  country_id: number;
  recaptcha3: string | null;
  recaptcha2: string | null;
  referral_code?: string;
};

export type TErrors = {
  email?: string;
  password?: string;
  phone?: string;
  code?: string;
  toe?: string;
  message?: string;
  name?: string;
};

declare global {
  interface Window {
    grecaptcha: any;
    onSubmit: any;
    ym: (id: number, type: string, goal: string) => void;
  }
}

const defaultErrors = (): TErrors => {
  return {
    email: "",
    password: "",
    phone: "",
    code: "",
    toe: "",
    name: "",
    message: "",
  };
};

const toastError = (message: string) => {
  toast.error(message, {
    position: "top-right",
    autoClose: 15000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

class App {
  isLoading: boolean = true;
  isMobileMenuOpen: boolean = false;

  loginData: ILoginData = {
    username: "",
    password: "",
    isRemember: false,
  };

  modalOpenNames: Array<string> = [];

  infoModalData: IInfoModalData = {
    slogan: "",
    title: "",
    content: "",
    buttonTitle: "",
    view: ModalView.Default,
    cbOnClickClose: undefined,
  };

  authModalData: IAuthModalData = {
    stepName: "",
  };

  countries: ICountry[] = [];
  onlyCountries: string[] = [];
  phoneMasks: {
    [key: string]: string;
  } = {};
  signupData: any = {
    data: {},
  };

  enterFormDefaultData: TEnterFormDefaultData = {
    phone: "",
    pass: "",
  };

  settings: TSettings = {
    version: 0,
    settings: {
      timezone: "Europe/Moscow",
      main_info: {
        email: "",
        phone: "",
      },
    },
    urls: {
      publicPageOwner: "https://vp.bidfox.ru",
      advertiser: "https://market.bidfox.ru",
    },
    video: {
      presentation: "https://www.youtube.com/embed/y1o6HPJhLkk",
      instruction: "https://www.youtube.com/embed/aLNncoeBtCw",
    },
  };

  recaptcha: TRecaptcha = {
    key2: null,
    key3: null,
    url: "https://www.google.com/recaptcha/api.js",
    tokenPwdReset: null,
    tokenPwdResetConfirm: null,
    tokenSignup: null,
    tokenFeedback: null,
    tokenEnter: null,
    type: 1,
    isShowRecaptchaPwdReset: false,
    isShowRecaptchaSignup: false,
    isShowRecaptchaFeedback: false,
    isShowRecaptchaEnter: false,
  };

  agreementPD = {
    content: "",
    title: "",
  };
  advertiserContract = {
    content: "",
    title: "",
  };
  ownerContract = {
    content: "",
    title: "",
  };
  ownerPriceList = {
    content: "",
    title: "",
  };
  privacyPolicy = {
    content: "",
    title: "",
  };
  softwareDocumentation = {
    content: "",
    title: "",
  };
  advertiserPriceList = {
    content: "",
    title: "",
  };
  userOferta = {
    id: 4,
    title: "Договор оферты",
    content: "",
    is_deleted: 0,
    created_at: "",
    updated_at: "",
  };

  isRecaptchaReady: boolean = false;
  refId: string = "";

  typePage = 1; //1 - owner, 2 - adv

  errors: TErrors = defaultErrors();
  errorsUpdated: number = new Date().getTime();

  constructor() {
    this.typePage = PAGE_TYPE === "owner" ? 1 : 2;

    try {
      const cookiesAuth = document.cookie.match(
        new RegExp(`(?:^|; )${process.env.REACT_APP_COOKIES_AUTH_NAME}=([^;]*)`)
      );

      //  console.log("cookiesAuth", cookiesAuth);
      if (cookiesAuth) {
        const tmpToken = localStorage.getItem("bidfox|user");
        if (tmpToken) {
          //      console.log("tmpToken", localStorage.getItem("bidfox|user"));
          const parsed = JSON.parse(tmpToken);
          if (tmpToken && parsed.token && parsed.token !== cookiesAuth[1]) {
            localStorage.setItem(
              "bidfox|user",
              JSON.stringify({ token: cookiesAuth[1] })
            );
          }
        }
        if (!tmpToken) {
          //    console.log("эставим токен из кукиэ");
          localStorage.setItem(
            "bidfox|user",
            JSON.stringify({ token: cookiesAuth[1] })
          );
        }
        document.cookie = `${process.env.REACT_APP_COOKIES_AUTH_NAME}=; ${process.env.REACT_APP_COOKIES_AUTH_OPTIONS} max-age=-1; expires=Thu, 01 Jan 1970 00:00:00 GMT;`;
      }

      const userAuth = localStorage.getItem("bidfox|user");

      if (window.location.href.match(/\/payment\/success/)) {
        const arrCampaignSeed = window.location.href.split("?Shp_campaign=");
        // console.log("arrCampaignSeed", arrCampaignSeed);
        if (arrCampaignSeed.length === 2) {
          localStorage.setItem(
            "bidfox|state",
            JSON.stringify({
              stateTo: "app.editCampaignSeed",
              campaignSeedId: arrCampaignSeed[1],
              isPaid: true,
              typeMessage: "payment-success",
            })
          );
        } else {
          localStorage.setItem(
            "bidfox|state",
            JSON.stringify({
              stateTo: "app.finance",
              isPaid: true,
              typeMessage: "payment-success",
            })
          );
        }
      }

      if (window.location.href.match(/\/payment\/fail/)) {
        const arrCampaignSeed = window.location.href.split("?Shp_campaign=");
        if (arrCampaignSeed.length === 2) {
          localStorage.setItem(
            "bidfox|state",
            JSON.stringify({
              stateTo: "app.editCampaignSeed",
              campaignSeedId: arrCampaignSeed[1],
              isPaid: false,
              typeMessage: "payment-fail",
            })
          );
        } else {
          localStorage.setItem(
            "bidfox|state",
            JSON.stringify({
              stateTo: "app.finance",
              isPaid: false,
              typeMessage: "payment-fail",
            })
          );
        }
      }

      if (userAuth) {
        const parsed = JSON.parse(userAuth);
        if (parsed && parsed.token) {
          window.location.href = "/p/";
        }
      } else {
        if (window.location.href.match(/\/ref\//)) {
          const sUrl = window.location.href.split("#");
          const refId = window.location.href.split("/").pop()?.toString();
          this.refId = refId || "";
          localStorage.setItem("bidfox|redirectto", sUrl[0] + "p/#" + sUrl[1]);
          localStorage.setItem("bidfox|ref", refId || "");
        }
      }
    } catch (e) {
      console.log("Error in userAuth");
    }
    try {
      const url = new URL(window.location.href);
      const _openParams = url.searchParams.get("open");
      let isInfoAdvModalNeedClose = false;
      if (_openParams === "login") {
        isInfoAdvModalNeedClose = true;
      } else if (_openParams === "signup") {
        isInfoAdvModalNeedClose = true;
      }

      const isAdblockNotified = localStorage.getItem("bidfox|adBlockNotified");
      if (!isAdblockNotified && !isInfoAdvModalNeedClose) {
        const adBlockMessage =
          "Для корректной работы сервиса рекомендуем отключить блокировщик рекламы или добавить наш сайт в исключения. Если этого не сделать, то некоторые функции или настройки могут работать некорректно.";
        fetch("https://googleads.g.doubleclick.net/pagead/id", {
          mode: "no-cors",
        })
          .then((res) => {
            if (res && res.redirected && res.url.substr(0, 4) != "http") {
              this.setInfoModalData({
                slogan: "Информация",
                buttonTitle: "Ознакомился",
                content: adBlockMessage,
                cbOnClickClose: () => {
                  localStorage.setItem("bidfox|adBlockNotified", "true");
                  this.modalClose(EModalNames.Info);
                },
              });
              this.modalOpen(EModalNames.Info);
            }
          })
          .catch((err) => {
            this.setInfoModalData({
              slogan: "Информация",
              buttonTitle: "Ознакомился",
              content: adBlockMessage,
              cbOnClickClose: () => {
                localStorage.setItem("bidfox|adBlockNotified", "true");
                this.modalClose(EModalNames.Info);
              },
            });
            this.modalOpen(EModalNames.Info);
          });
      }
    } catch (e) {
      console.log("Error in test adblock");
    }
    fetch(`${API_URL}/settings/common`)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        this.settings = data;

        if (data && data.settings && data.settings.recaptcha) {
          this.recaptcha = data.settings.recaptcha;
          if (this.recaptcha.key3) {
            const script = document.createElement("script");
            // script.src = `${this.recaptcha.url}?render=explicit`
            script.src = `${this.recaptcha.url}?render=${this.recaptcha.key3}`;
            script.addEventListener(
              "load",
              () => (this.isRecaptchaReady = true)
            );
            document.body.appendChild(script);
          }
        }
      })
      .catch((e) => console.log("common error", e));
    fetch(`${API_URL}/lookup/country`)
      .then((res) => {
        return res.json();
      })
      .then((data: ICountry[]) => {
        this.countries = data;
        this.onlyCountries = data
          .filter((v) => v.registration === 1)
          .map((v) => v.code);

        let phoneMask: { [key: string]: string } = {};
        for (let key in data) {
          phoneMask[data[key].code] = data[key].mask
            .replaceAll("_", ".")
            .substring(data[key].maskPrefix.length + 1);
        }
        this.phoneMasks = phoneMask;
      })
      .catch((e) => console.log("country error", e));
    fetch(`${API_URL}/cms/general/personal-data-policy`)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        this.agreementPD = data;
      })
      .catch((e) => console.log("agreementPD error", e));

    fetch(`${API_URL}/cms/general/advertiser-contract`)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        this.advertiserContract = data;
      })
      .catch((e) => console.log("advertiserContract error", e));

    fetch(`${API_URL}/cms/general/owner-contract`)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        this.ownerContract = data;
      })
      .catch((e) => console.log("ownerContract error", e));

    fetch(`${API_URL}/cms/general/privacy-policy`)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        this.privacyPolicy = data;
      })
      .catch((e) => console.log("privacy-policy error", e));

    fetch(`${API_URL}/cms/general/software-documentation`)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        this.softwareDocumentation = data;
      })
      .catch((e) => console.log("software-documentation error", e));

    fetch(`${API_URL}/cms/general/advertiser-price-list`)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        this.advertiserPriceList = data;
      })
      .catch((e) => console.log("advertiserPriceList error", e));

    fetch(`${API_URL}/cms/general/owner-price-list`)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        this.ownerPriceList = data;
      })
      .catch((e) => console.log("ownerPriceList error", e));

    fetch(`${API_URL}/cms/page/agreement-public`)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        this.userOferta = data;
      })
      .catch((e) => console.log("userOferta error", e));

    this.isLoading = false;
    makeAutoObservable(this);
  }

  changeTypePage(newType: number, openModalName: string) {
    if (newType !== this.typePage) {
      setTimeout(() => {
        if (newType === 1) {
          window.location.href = `${this.settings.urls.publicPageOwner}?open=${openModalName}`;
        } else {
          window.location.href = `${this.settings.urls.advertiser}?open=${openModalName}`;
        }
      }, 300);
    }
  }

  setRecaptchaToken(action: string) {
    if (this.recaptcha.key3 === null) {
      return;
    }
    if (this.isRecaptchaReady === false) {
      toastError(
        "Возникла ошибка при загрузке recaptcha. Попробуйте перезагрузить страницу."
      );
      return;
    }
    switch (action) {
      case "login":
        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute(this.recaptcha.key3, { action })
            .then((token: string) => {
              this.recaptcha.tokenEnter = token;
            });
        });
        break;
      case "signup":
        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute(this.recaptcha.key3, { action })
            .then((token: string) => {
              this.recaptcha.tokenSignup = token;
            });
        });
        break;
      case "password_reset":
        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute(this.recaptcha.key3, { action })
            .then((token: string) => {
              this.recaptcha.tokenPwdReset = token;
            });
        });
        break;
      case "feedback":
        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute(this.recaptcha.key3, { action })
            .then((token: string) => {
              this.recaptcha.tokenFeedback = token;
            });
        });
        break;

      default:
        break;
    }
  }

  setRecaptcha2Token(token: string | null, action: string) {
    switch (action) {
      case "login":
        this.recaptcha.tokenEnter = token;
        break;
      case "signup":
        this.recaptcha.tokenSignup = token;
        break;
      case "password_reset":
        this.recaptcha.tokenPwdReset = token;
        break;
      case "password_reset_confirm":
        this.recaptcha.tokenPwdResetConfirm = token;
        break;
      case "feedback":
        this.recaptcha.tokenFeedback = token;
        break;

      default:
        break;
    }
  }

  resetRecaptcha2() {
    window.grecaptcha.reset();
  }

  modalOpen(name: string) {
    this.errors = defaultErrors();
    this.modalOpenNames = [...this.modalOpenNames, name];
  }

  modalClose(name: string) {
    this.errors = defaultErrors();
    this.modalOpenNames = this.modalOpenNames.filter(
      (openName) => openName !== name
    );
  }

  setAuthModalData(stepName: EAuthModalSteps) {
    this.recaptcha.isShowRecaptchaSignup = false;
    this.recaptcha.isShowRecaptchaEnter = false;

    this.errors = defaultErrors();
    switch (stepName) {
      case EAuthModalSteps.RecoveryPassword:
        this.setRecaptchaToken("password_reset");
        break;
      case EAuthModalSteps.Register:
        if (this.typePage === 2 && window.ym) {
          window.ym(44464504, "reachGoal", "registration-form-openned");
        }
        this.setRecaptchaToken("signup");
        break;
      case EAuthModalSteps.Enter:
        this.setRecaptchaToken("login");
        break;
      default:
        break;
    }
    this.authModalData.stepName = stepName;
  }

  removeLoginModalData() {
    this.authModalData.stepName = "";
  }

  setInfoModalData({
    slogan,
    title,
    content,
    buttonTitle,
    view = ModalView.Default,
    cbOnClickClose = () => {},
  }: IInfoModalData) {
    this.infoModalData.slogan = slogan;
    this.infoModalData.title = title;
    this.infoModalData.content = content;
    this.infoModalData.buttonTitle = buttonTitle;
    this.infoModalData.view = view;
    this.infoModalData.cbOnClickClose = cbOnClickClose;
  }

  resetInfoModalData() {
    this.infoModalData.slogan = "";
    this.infoModalData.title = "";
    this.infoModalData.content = "";
    this.infoModalData.buttonTitle = "";
    this.infoModalData.view = ModalView.Default;
  }

  toggleMobileMenu() {
    this.isMobileMenuOpen = !this.isMobileMenuOpen;
  }

  async authLogin(data: IEnterSubmitData) {
    this.errors = defaultErrors();
    this.enterFormDefaultData = { phone: "", pass: "" };
    this.errors.phone = ERROR_MESSAGES.fieldDefaultError;
    this.errors.password = ERROR_MESSAGES.fieldDefaultError;
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;

    const req = {
      email: data.phone.number,
      password: data.password,
      recaptcha3: this.recaptcha.isShowRecaptchaEnter
        ? null
        : this.recaptcha.tokenEnter,
      recaptcha2: this.recaptcha.isShowRecaptchaEnter
        ? this.recaptcha.tokenEnter
        : null,
    };

    if (!req.email.toString().trim().length) {
      this.errors.phone = ERROR_MESSAGES.fieldIsRequeredError;
      this.errorsUpdated = new Date().getTime();
      toastError(ERROR_MESSAGES.isRequeredError);
      this.isLoading = false;
      return;
    }
    if (!req.password.trim().length) {
      this.errors.password = ERROR_MESSAGES.fieldIsRequeredError;
      this.errorsUpdated = new Date().getTime();
      toastError(ERROR_MESSAGES.isRequeredError);
      this.isLoading = false;
      return;
    }

    try {
      const res = await fetch(`${API_URL}/auth/login/index`, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
        },
        referrerPolicy: "no-referrer",
        body: JSON.stringify(req),
      });

      this.recaptcha.tokenEnter = null;

      if (this.recaptcha.isShowRecaptchaEnter) {
        this.resetRecaptcha2();
      }

      if (res.status >= 500) {
        toastError(ERROR_MESSAGES.server);
        this.isLoading = false;
        return;
      }

      const errors = this.errors;
      const self = this;

      res.json().then(function (body: any) {
        if (res.status === 422) {
          let isAlreadySayRecaptcha = false;
          // TErrorResBody[]
          self.errorsUpdated = new Date().getTime();
          body.forEach((v: TErrorResBody) => {
            if (v.field === "recaptcha3") {
              self.recaptcha.isShowRecaptchaEnter = true;
            }
            if (v.field === "recaptcha3" && !isAlreadySayRecaptcha) {
              isAlreadySayRecaptcha = true;
              self.enterFormDefaultData = {
                phone: String(req.email),
                pass: req.password,
              };
              self.errors.phone = "";
              self.errors.password = "";
              self.errorsUpdated = new Date().getTime();
              toastError("Пройдите проверку на анитибота");
            }
            if (v.field === "recaptcha2" && !isAlreadySayRecaptcha) {
              isAlreadySayRecaptcha = true;
              self.enterFormDefaultData = {
                phone: String(req.email),
                pass: req.password,
              };
              self.errors.phone = "";
              self.errors.password = "";
              self.errorsUpdated = new Date().getTime();
              toastError("Пройдите проверку на анитибота");
            }
            if (v.field === "email") {
              toastError(v.message);
              errors.phone = ERROR_MESSAGES.fieldDefaultError;
              errors.password = ERROR_MESSAGES.fieldDefaultError;
            }
          });
          self.isLoading = false;
          return;
        }

        if (res.status === 200 && body.access_token) {
          // TSuccessLogin
          localStorage.setItem(
            "bidfox|user",
            JSON.stringify({ token: body.access_token })
          );
          window.location.href = "/p/";
          self.isLoading = false;
          return;
        }

        toastError(ERROR_MESSAGES.server);
        self.isLoading = false;
        return;
      });
    } catch (e) {
      toastError(ERROR_MESSAGES.server);
      console.log("error on login", e);
      toastError(e);
      this.isLoading = false;
    }
  }

  async authRecoveryPassword(data: IRecoveryPasswordSubmitData) {
    // {"contact":"75561561651","recaptcha3":null,"recaptcha2":null}
    // POST https://api.cpa.dev.bidfox.ru/auth/password-reset/request
    this.errors = defaultErrors();
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;

    const req = {
      contact: data.phone.number,
      recaptcha3: this.recaptcha.isShowRecaptchaPwdReset
        ? null
        : this.recaptcha.tokenPwdReset,
      recaptcha2: this.recaptcha.isShowRecaptchaPwdReset
        ? this.recaptcha.tokenPwdReset
        : null,
    };

    if (!req.contact.toString().trim().length) {
      this.errors.phone = ERROR_MESSAGES.fieldIsRequeredError;
      this.errorsUpdated = new Date().getTime();
      toastError(ERROR_MESSAGES.isRequeredError);
      this.isLoading = false;
      return;
    }

    try {
      const res = await fetch(`${API_URL}/auth/password-reset/request`, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
        },
        referrerPolicy: "no-referrer",
        body: JSON.stringify(req),
      });

      this.recaptcha.tokenPwdReset = null;

      if (this.recaptcha.isShowRecaptchaPwdReset) {
        this.resetRecaptcha2();
      }

      if (res.status >= 500) {
        toastError(ERROR_MESSAGES.server);
        this.isLoading = false;
        return;
      }

      const errors = this.errors;
      const authModalData = this.authModalData;
      const self = this;

      res.json().then(function (body: any) {
        if (res.status === 422) {
          let isAlreadySayRecaptcha = false;
          body.forEach((v: TErrorResBody) => {
            if (v.field === "recaptcha3") {
              self.recaptcha.isShowRecaptchaPwdReset = true;
            }
            if (v.field === "recaptcha3" && !isAlreadySayRecaptcha) {
              isAlreadySayRecaptcha = true;
              toastError("Пройдите проверку на анитибота");
            }
            if (v.field === "recaptcha2" && !isAlreadySayRecaptcha) {
              isAlreadySayRecaptcha = true;
              toastError("Пройдите проверку на анитибота");
            }
            if (v.field === "phone") {
              errors.phone = ERROR_MESSAGES.fieldDefaultError;
              toastError(v.message);
            }
            if (v.field === "contact") {
              toastError(v.message);
            }
          });
          // TErrorResBody[]
          self.errorsUpdated = new Date().getTime();

          self.isLoading = false;
          return;
        }

        if (res.status === 200 && body.success && body.success === true) {
          // TSuccessLogin
          authModalData.stepName = EAuthModalSteps.RecoveryPasswordConfirmation;
          self.isLoading = false;
          return;
        }

        toastError(ERROR_MESSAGES.server);
        self.isLoading = false;
        return;
      });
    } catch (e) {
      toastError(ERROR_MESSAGES.server);
      console.log("error on recovery password", e);
      toastError(e);
      this.isLoading = false;
    }
  }
  async authRecoveryPasswordConfirm(
    data: IRecoveryPasswordConfirmationSubmitData
  ) {
    // {"token":"2323232323","password":"11111111"}
    // POST https://api.cpa.dev.bidfox.ru/auth/password-reset/index
    this.errors = defaultErrors();
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;
    const req = {
      token: data.code,
      password: data.password,
      recaptcha2: this.recaptcha.tokenPwdResetConfirm
        ? this.recaptcha.tokenPwdResetConfirm
        : null,
    };

    if (!req.token.toString().trim().length) {
      this.errors.code = ERROR_MESSAGES.fieldIsRequeredError;
      this.errorsUpdated = new Date().getTime();
      toastError(ERROR_MESSAGES.isRequeredError);
      this.isLoading = false;
      return;
    }
    if (!req.password.trim().length) {
      this.errors.password = ERROR_MESSAGES.fieldIsRequeredError;
      this.errorsUpdated = new Date().getTime();
      toastError(ERROR_MESSAGES.isRequeredError);
      this.isLoading = false;
      return;
    }

    try {
      const res = await fetch(`${API_URL}/auth/password-reset/index`, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
        },
        referrerPolicy: "no-referrer",
        body: JSON.stringify(req),
      });

      this.recaptcha.tokenPwdResetConfirm = null;
      this.resetRecaptcha2();

      if (res.status >= 500) {
        toastError(ERROR_MESSAGES.server);
        this.isLoading = false;
        return;
      }

      const self = this;

      res.json().then((body: any) => {
        if (res.status === 422) {
          let isAlreadySayRecaptcha = false;
          // TErrorResBody[]
          body.forEach((v: TErrorResBody) => {
            if (v.field === "token") {
              toastError(v.message);
            }
            if (v.field === "recaptcha2" && !isAlreadySayRecaptcha) {
              isAlreadySayRecaptcha = true;
              toastError("Пройдите проверку на анитибота");
            }
          });
          self.isLoading = false;

          return;
        }

        if (res.status === 200 && body.success && body.success === true) {
          self.modalOpen(EModalNames.Info);
          self.setInfoModalData({
            slogan: "Пароль успешно изменен",
            buttonTitle: "ОК",
            content:
              '<p style="text-align:center;">Вы успешно изменили свой пароль. <br> Попробуйте сейчас войти личный кабинет.</p>',
            cbOnClickClose: () => {
              self.modalClose(EModalNames.Info);
              self.resetInfoModalData();
              self.authModalData.stepName = EAuthModalSteps.Enter;
            },
          });
          self.isLoading = false;
          return;
        }

        toastError(ERROR_MESSAGES.server);
        self.isLoading = false;
        return;
      });
    } catch (e) {
      toastError(ERROR_MESSAGES.server);
      console.log("error on recovery password pin", e);
      toastError(e);
      this.isLoading = false;
    }
  }

  async authRegistration(data: IRegisterSubmitData) {
    // https://api.cpa.dev.bidfox.ru/auth/signup
    // {"password":"111111","phone":"79129876543","email":"newuser@bidfox.ru","phoneDirty":"+7(912)987-65-43","country_id":1,"recaptcha3":null}
    // {"id":231,"email":"newuser@bidfox.ru","phone":{"id":512,"type":1,"data":"79129876543","verification_status":2,"is_main":1,"created_at":"2021-06-30 11:14:19","updated_at":"2021-06-30 11:14:19","parameters":{"country":{"id":1,"name":"Россия","code":"ru","image":"https://api.cpa.dev.bidfox.ru/pictures/flags/mini/22/3f/6b/223f6bbf0932ac0ed5a27867e0a0a71f.jpg","mask":"+7(___)___-__-__","registration":1,"default_currency_id":1,"is_deleted":0,"mcc":"250","currency":{"id":1,"name":"Рубли","symbol":"руб.","is_deleted":0},"maskRegex":"/\\+7\\([0-9][0-9][0-9]\\)[0-9][0-9][0-9]\\-[0-9][0-9]\\-[0-9][0-9]/","maskPrefix":"7"}}}}
    this.errors = defaultErrors();
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;

    const countryFilter = this.countries.filter(
      (v) => v.code === data.phone.country.countryCode
    );

    if (!countryFilter || !countryFilter.length) {
      this.errors.phone = ERROR_MESSAGES.fieldIsRequeredError;
      this.errorsUpdated = new Date().getTime();
      toastError(ERROR_MESSAGES.isRequeredError);
      this.isLoading = false;
      return;
    }

    let req: TSignup = {
      password: data.password,
      phone: data.phone.number,
      email: data.email,
      phoneDirty: data.phone.number,
      country_id:
        countryFilter && countryFilter.length ? countryFilter[0].id : 0,
      recaptcha3: this.recaptcha.isShowRecaptchaSignup
        ? null
        : this.recaptcha.tokenSignup,
      recaptcha2: this.recaptcha.isShowRecaptchaSignup
        ? this.recaptcha.tokenSignup
        : null,
      referral_code: this.refId,
    };

    if (!this.refId) {
      delete req.referral_code;
    }

    if (
      !req.email.toString().trim().length ||
      !req.phone.toString().trim().length
    ) {
      this.errors.email = ERROR_MESSAGES.fieldIsRequeredError;
      this.errors.phone = ERROR_MESSAGES.fieldIsRequeredError;
      this.errorsUpdated = new Date().getTime();
      toastError(ERROR_MESSAGES.isRequeredError);
      this.isLoading = false;
      return;
    }
    // if (!req.phone.toString().trim().length) {
    //   this.errors.phone = ERROR_MESSAGES.fieldIsRequeredError;
    //   this.errorsUpdated = new Date().getTime();
    //   toastError(ERROR_MESSAGES.isRequeredError);
    //   this.isLoading = false;
    //   return;
    // }
    if (!req.password.trim().length) {
      this.errors.password = ERROR_MESSAGES.fieldIsRequeredError;
      this.errorsUpdated = new Date().getTime();
      toastError(ERROR_MESSAGES.isRequeredError);
      this.isLoading = false;
      return;
    }

    try {
      const res = await fetch(`${API_URL}/auth/signup`, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
        },
        referrerPolicy: "no-referrer",
        body: JSON.stringify(req),
      });

      this.recaptcha.tokenSignup = null;

      if (this.recaptcha.isShowRecaptchaSignup) {
        this.resetRecaptcha2();
      }

      if (res.status >= 500) {
        toastError(ERROR_MESSAGES.server);
        this.isLoading = false;
        return;
      }

      const errors = this.errors;
      const authModalData = this.authModalData;
      const signupData = this.signupData;
      const self = this;

      res.json().then(function (body: any) {
        if (res.status === 422) {
          let isAlreadySayRecaptcha = false;
          body.forEach((v: TErrorResBody) => {
            if (v.field === "recaptcha3") {
              self.recaptcha.isShowRecaptchaSignup = true;
            }
            if (v.field === "recaptcha3" && !isAlreadySayRecaptcha) {
              isAlreadySayRecaptcha = true;
              toastError("Пройдите проверку на анитибота");
            }
            if (v.field === "recaptcha2" && !isAlreadySayRecaptcha) {
              isAlreadySayRecaptcha = true;
              toastError("Пройдите проверку на анитибота");
            }
            if (v.field === "phone" || v.field === "email") {
              errors.phone = ERROR_MESSAGES.fieldDefaultError;
              errors.email = ERROR_MESSAGES.fieldDefaultError;

              toastError(v.message);
            }
            if (v.field === "password") {
              errors.password = ERROR_MESSAGES.fieldDefaultError;
              toastError(v.message);
            }
            // if (v.field === "email") {
            //   errors.email = ERROR_MESSAGES.fieldDefaultError;
            //   toastError(v.message);
            // }
          });

          self.errorsUpdated = new Date().getTime();

          self.isLoading = false;

          return;
        }

        if (res.status === 200 && body.id) {
          if (self.typePage === 2 && window.ym) {
            window.ym(44464504, "reachGoal", "registration-form-approved");
          }

          signupData.data = body;
          authModalData.stepName = EAuthModalSteps.RegisterConfirmation;
          self.isLoading = false;
          return;
        }

        toastError(ERROR_MESSAGES.server);
        self.isLoading = false;
        return;
      });
    } catch (e) {
      toastError(ERROR_MESSAGES.server);
      console.log("error on registration", e);
      toastError(e);
      this.isLoading = false;
    }
  }

  async authRegistrationConfirm(data: IRegisterConfirmationSubmitData) {
    // https://api.cpa.dev.bidfox.ru/auth/signup/confirm?id=231
    // {"contact":512,"code":"992759"}
    this.errors = defaultErrors();
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;

    const req = {
      code: data.code,
      contact: this.signupData.data.phone.id,
    };

    if (!req.code.toString().trim().length) {
      this.errors.code = ERROR_MESSAGES.fieldIsRequeredError;
      toastError(ERROR_MESSAGES.isRequeredError);
      this.errorsUpdated = new Date().getTime();
      this.isLoading = false;
      return;
    }

    try {
      const res = await fetch(
        `${API_URL}/auth/signup/confirm?id=${this.signupData.data.id}`,
        {
          method: "POST",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
          },
          referrerPolicy: "no-referrer",
          body: JSON.stringify(req),
        }
      );

      if (res.status >= 500) {
        toastError(ERROR_MESSAGES.server);
        this.isLoading = false;
        return;
      }

      const errors = this.errors;
      const self = this;

      res.json().then(function (body: any) {
        if (res.status === 422) {
          errors.code = ERROR_MESSAGES.fieldDefaultError;
          self.errorsUpdated = new Date().getTime();
          body.forEach((v: TErrorResBody) => {
            toastError(v.message);
          });
          self.isLoading = false;

          return;
        }

        if (res.status === 200 && body && body.access_token) {
          if (self.typePage === 2 && window.ym) {
            window.ym(44464504, "reachGoal", "registration");
          }

          // TSuccessLogin
          // self.modalOpen(EModalNames.Info)
          self.setInfoModalData({
            slogan: "Регистрация завершена",
            buttonTitle: "Войти в личный кабинет",
            content:
              '<p style="text-align: center">Вы успешно зарегистрировались в системе Bidfox. <br> Теперь вы можете войти в личный кабинет.</p>',
            cbOnClickClose: () => {
              localStorage.setItem(
                "bidfox|user",
                JSON.stringify({ token: body.access_token })
              );
              window.location.href = "/p/";
            },
          });
          self.modalOpen(EModalNames.Info);

          self.isLoading = false;
          return;
        }

        toastError(ERROR_MESSAGES.server);
        self.isLoading = false;
        return;
      });
    } catch (e) {
      toastError(ERROR_MESSAGES.server);
      console.log("error on registration pin", e);
      toastError(e);
      this.isLoading = false;
    }
  }

  emailValidate(value: string) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
      return true;
    }
    return false;
  }
  phoneValidate(value: string) {
    if (
      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(value)
    ) {
      return true;
    }
    return false;
  }

  async feedbackSend(data: IFeedbackFormInitialInputs) {
    this.errors = defaultErrors();
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;

    const toe = data.toe.trim();
    const message = data.message.trim();
    const name = data.name.trim();
    let phone = null;
    let email = null;
    if (!toe || !message || !name) {
      toastError(ERROR_MESSAGES.isRequeredError);
      this.isLoading = false;
      return;
    }

    if (this.emailValidate(toe)) {
      email = toe;
    } else if (this.phoneValidate(toe)) {
      phone = toe;
    } else {
      toastError(ERROR_MESSAGES.fieldTOEError);
      this.isLoading = false;
      this.errors.toe = ERROR_MESSAGES.fieldDefaultError;
      this.errorsUpdated = new Date().getTime();
      return;
    }

    const req = {
      name: name,
      email: email,
      phone: phone,
      message: data.message,
      recaptcha3: this.recaptcha.isShowRecaptchaFeedback
        ? null
        : this.recaptcha.tokenFeedback,
      recaptcha2: this.recaptcha.isShowRecaptchaFeedback
        ? this.recaptcha.tokenFeedback
        : null,
    };

    try {
      const res = await fetch(`${API_URL}/user/feedback/create`, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
        },
        referrerPolicy: "no-referrer",
        body: JSON.stringify(req),
      });

      this.recaptcha.tokenFeedback = null;

      if (this.recaptcha.isShowRecaptchaFeedback) {
        this.resetRecaptcha2();
      }

      if (res.status >= 500) {
        toastError(ERROR_MESSAGES.server);
        this.isLoading = false;
        return;
      }

      const self = this;

      res.json().then(function (body: any) {
        if (res.status === 422) {
          self.recaptcha.tokenFeedback = null;

          let isAlreadySayRecaptcha = false;
          body.forEach((v: TErrorResBody) => {
            if (v.field === "recaptcha3") {
              self.recaptcha.isShowRecaptchaFeedback = true;
            }
            if (v.field === "recaptcha3" && !isAlreadySayRecaptcha) {
              isAlreadySayRecaptcha = true;
              toastError("Пройдите проверку на анитибота");
            }
            if (v.field === "recaptcha2" && !isAlreadySayRecaptcha) {
              isAlreadySayRecaptcha = true;
              toastError("Пройдите проверку на анитибота");
            }
            if (v.field === "email") {
              self.errors.toe = ERROR_MESSAGES.fieldTOEError;
              toastError(v.message);
            }
            if (v.field === "phone") {
              self.errors.toe = ERROR_MESSAGES.fieldTOEError;
              toastError(v.message);
            }
            if (v.field === "message") {
              toastError(v.message);
              self.errors.message = ERROR_MESSAGES.fieldIsRequeredError;
            }
            if (v.field === "name") {
              self.errors.name = ERROR_MESSAGES.fieldIsRequeredError;
              toastError(v.message);
            }
          });

          self.errorsUpdated = new Date().getTime();

          self.isLoading = false;
          return;
        }

        if (res.status === 200) {
          self.modalOpen(EModalNames.Info);
          self.setInfoModalData({
            slogan: "Сообщение отправлено",
            buttonTitle: "ОК",
            content: "Мы свяжемся с вами в ближайшее время",
            cbOnClickClose: () => {
              self.modalClose(EModalNames.Info);
              self.resetInfoModalData();
              window.location.reload();
            },
          });
          self.isLoading = false;
          return;
        }

        toastError(ERROR_MESSAGES.server);
        self.isLoading = false;
        return;
      });
    } catch (e) {
      toastError(ERROR_MESSAGES.server);
      console.log("error on recovery password", e);
      toastError(e);
      this.isLoading = false;
    }
  }
}

const storeApp = new App();

export default storeApp;
