import { ReactComponent as SettingsSVG } from '../../svg/common/settings.svg'
import { ReactComponent as MonetizationSVG } from '../../svg/vp/monetization.svg'
import { ReactComponent as RegisterSVG } from '../../svg/vp/register.svg'
import { ReactComponent as AutomationSVG } from '../../svg/vp/automation.svg'
import { ReactComponent as FederationSVG } from '../../svg/vp/federation.svg'
import { ReactComponent as OutputMoneySVG } from '../../svg/vp/output.svg'
import { ReactComponent as AnaliticsSVG } from '../../svg/vp/analitics.svg'
import { ReactComponent as PartnerSVG } from '../../svg/vp/partner.svg'

import {
  ISocials,
  IContacts,
  INavigations,
  IExternalNavigations, ISliders
} from '../../components/common/types'

import { TAdvantages } from '../../components/types'
import { TReferralList, THowWorkList } from './types'
import { SectionNamesVP } from './sections/names'

export const navigations: INavigations = [
  {
    id: 1,
    name: 'Кто мы',
    href: SectionNamesVP.Welcome,
  },
  {
    id: 2,
    name: 'Способы монетизации',
    href: SectionNamesVP.Monetization,
  },
  {
    id: 3,
    name: 'Как работает сервис',
    href: SectionNamesVP.Work,
  },
  {
    id: 4,
    name: 'Наши преимущества',
    href: SectionNamesVP.Advantages,
  },
  {
    id: 5,
    name: 'Реферальная программа',
    href: SectionNamesVP.Referral,
  },
  {
    id: 6,
    name: 'О нас',
    href: SectionNamesVP.About,
  },
  {
    id: 7,
    name: 'Контакты',
    href: SectionNamesVP.Contacts,
  },
]

export const externalLinks: IExternalNavigations = [
  {
    id: 8,
    name: 'FAQ',
    href: 'https://sites.google.com/view/bidfoxrk/',
  }
]

export const socials: ISocials = [
  {
    id: 1,
    name: 'Telegram',
    systemName: 'tg',
    href: 'https://t.me/bidfox_partners',
  },
  {
    id: 2,
    name: 'ВКонтакте',
    systemName: 'vk',
    href: 'https://vk.com/smmbidfox',
  },
  {
    id: 3,
    name: 'FAQ',
    systemName: 'question',
    href: 'https://sites.google.com/view/bidfoxrk/',
  }
]

export const contacts: IContacts = [
  {
    id: 1,
    title: '8 800 777 53 14',
    subTitle: 'Звонок бесплатный',
    systemName: 'phone',
    href: 'tel:88007775314',
  },
  {
    id: 2,
    title: 'Почта технической поддержки',
    subTitle: 'support@bidfox.ru',
    systemName: 'mail',
    href: 'mailto:support@bidfox.ru',
  },
  {
    id: 3,
    title: 'Официальный канал новостей',
    subTitle: 't.me/bidfox_partners',
    systemName: 'tg',
    href: 'https://t.me/bidfox_partners',
  },
  {
    id: 4,
    title: 'Наша группа ВКонтакте',
    subTitle: 'vk.com/smmbidfox',
    systemName: 'vk',
    href: 'https://vk.com/smmbidfox',
  }
]

export const HowToWorkCompanyList = [
  {
    id: 1,
    content: SettingsSVG,
    title: 'Проанализирует твои паблики <br /> по основным параметрам',
  },
  {
    id: 2,
    content: SettingsSVG,
    title: 'Автоматически опубликует <br /> посты согласно расписанию',
  },
  {
    id: 3,
    content: SettingsSVG,
    title: 'Подберет наиболее подходящую и эффективную рекламу',
  },
  {
    id: 4,
    content: SettingsSVG,
    title: 'Самостоятельно соберет необходимые данные для анализа',
  },
  {
    id: 5,
    content: SettingsSVG,
    title: 'Предложит твои паблики доступным Рекламодателям',
  },
  {
    id: 6,
    content: SettingsSVG,
    title: 'Начислит вознаграждение <br /> после соблюдения условий',
  }
]

export const HowToWorkYouList: THowWorkList = [
  {
    id: 1,
    content: RegisterSVG,
    title: 'Зарегистрироваться и добавить в систему нужные группы',
  },
  {
    id: 2,
    content: MonetizationSVG,
    title: 'Выбрать и подключить <br /> желаемый способ монетизации',
  },
  {
    id: 3,
    content: AutomationSVG,
    title: 'Установить автоматический <br /> или ручной режим работы',
  }
]

export const advantagesList: TAdvantages = [
  {
    id: 1,
    Icon: MonetizationSVG,
    title: 'Несколько способов <br /> монетизации группы',
    tooltip: 'У нас комплексный подход к выбору рекламодателей и способам монетизации. Мы предлагаем разные модели оплаты: CPM с оплатой фактического охвата или CPA с оплатой полезного действия. Работаем с интересными и проверенными крупными клиентами.',
  },
  {
    id: 2,
    Icon: AutomationSVG,
    title: 'Автоматизация <br /> всех процессов',
    tooltip: 'Для твоего удобства в сервисе реализована возможность автоматического режима публикации постов - настраивается один раз для паблика или для всех пабликов сразу. Но также есть возможность включить полуавтоматический или ручной режимы.',
  },
  {
    id: 3,
    Icon: OutputMoneySVG,
    title: 'Быстрая и легальная система <br /> вывода средств',
    tooltip: 'Доступны несколько способов выплаты вознаграждений, в том числе через систему «Самозанятые». Мы сотрудничаем с официальным Партнером ФНС России, который позволяет быстро, удобно и легально получить выплату. Все налоги BidFox оплачивает самостоятельно.',
  },
  {
    id: 4,
    Icon: PartnerSVG,
    title: 'Совместимость <br /> с Партнерской программой',
    tooltip: 'Нативная реклама от BidFox согласована и совместима с партнерской программой Одноклассников и Рекламной сетью ВКонтакте. Нашей командой пишутся посты и согласовываются с представителями социальных сетей.',
  },
  {
    id: 5,
    Icon: FederationSVG,
    title: 'Работаем с федеральными <br /> брендами',
    tooltip: 'У нас есть отдельная команда, которая занимается поиском и подключением крупных рекламодателей, а также команда, которая разрабатывает уникальный контент под них. Такие клиенты закупают гораздо большие объемы трафика, чем локальный бизнес.',
  },
  {
    id: 6,
    Icon: AnaliticsSVG,
    title: 'Удобная система анализа <br /> своих пабликов',
    tooltip: 'В сервисе реализованы полезные отчеты для анализа сообществ. Особенно удобны они будут для тех, у кого много сообществ. Отчеты позволяют анализировать развитие пабликов и их ведение другими менеджерами.',
  }
]

export const methodsMonetizations: ISliders = [
  {
    id: 1,
    slogan: 'Медиапост «Обмены»',
    title: 'Оплата по модели CPM – за каждую 1 000 охвата',
    subTitle: 'Размещение поста с рекламой другого сообщества из этой же социальной сети. Такой пост не занимает промо место, а креатив дополняется контентными составляющими. Оплата по модели CPM – за каждую 1 000 охвата.',
    systemName: 'mpo',
  },
  {
    id: 2,
    slogan: 'Медиапост «Сайты»',
    title: 'Данный тип монетизации пока на стадии тестирования',
    subTitle: 'В паблике будет размещен пост со сниппетом на внешний сайт, к сниппету будут прикреплены контентные составляющие. Данный тип монетизации пока на стадии тестирования.',
    systemName: 'mpw',
  },
  {
    id: 3,
    slogan: 'Нативная Биржа',
    title: 'Оплата по модели CPM – за каждую 1 000 охвата',
    subTitle: 'Размещение контентного поста с нативной интеграцией бренда. Посты не занимают промо место и совместимы с партнерскими программами Одноклассников и ВКонтакте. Оплата по модели CPM – за каждую 1 000 охвата.',
    systemName: 'nb',
  },
  {
    id: 4,
    slogan: 'Прямая Биржа',
    title: 'Оплата по модели CPM – за каждую 1 000 охвата',
    subTitle: 'Размещение поста с пометкой «Промо». Заявки на такие размещения поступают как от крупных федеральных брендов, так и от локальных ресторанов или магазинов. Оплата по модели CPM – за каждую 1 000 охвата.',
    systemName: 'pb',
  },
  {
    id: 5,
    slogan: 'CPA реклама',
    title: 'Оплата по модели CPA – за полезное действие',
    subTitle: 'Размещение рекламных постов с пометкой «Промо». Мы предлагаем офферы из партнерских программ с наиболее эффективными показателями. Оплата по модели CPA – за полезное действие на условиях Партнера.',
    systemName: 'cpa',
  },
  {
    id: 6,
    slogan: 'Медиапост «Видео»',
    title: 'Оплата по модели CPM – за каждую 1 000 охвата',
    subTitle: 'В паблике размещается контентный пост с видео. У видео от рекламодателей контентный и развлекательный посыл: глобальные события, обзоры, тематические видео. Оплата по модели CPM – за каждую 1 000 охвата.',
    systemName: 'youtube',
  },
]

export const ReferralList: TReferralList = [
  {
    id: 1,
    content: '',
    title: 'Ты регистрируешься в системе и получаешь свою реферальную ссылку',
  },
  {
    id: 2,
    content: '',
    title: 'Другой администратор сообщества регистрируется по ней в BidFox',
  },
  {
    id: 3,
    content: '',
    title: 'Он добавляет в систему паблики и включает в них любую Биржу или CPA',
  },
  {
    id: 4,
    content: '',
    title: 'Ты получаешь свой процент за все его заявки с Биржи или заказы CPA',
  },
  {
    id: 5,
    content: '',
    title: 'Также ты получишь единоразовый бонус за количество подписчиков в группах',
  },
  {
    id: 6,
    content: '',
    title: 'В программе нет никаких ограничений или лимитов, все честно и прозрачно',
  },
]
