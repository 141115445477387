import { ISliderSection, IWelcomeSection } from "../../../types/sections";
import WelcomeImage from "../../../components/_VP/WelcomeImage";
import { methodsMonetizations } from "../index";
import { SectionNamesVP } from "./names";

export const welcomeContent: IWelcomeSection = {
  marking: {
    ord: "ОРД",
    text: "Автоматическая маркировка рекламы"
  },

  slogan: "Сервис монетизации пабликов в социальных сетях",
  title:
    "Bidfox предлагает автоматическое размещение рекламы в сообществах. В системе предусмотрены разные способы монетизации паблика с гибкой системой настроек. Сервис повышает эффективность работы за счет собственной команды поиска рекламодателей и разработки контента.",
  buttonMain: {
    title: "Администраторам",
    href: "https://vp.bidfox.ru",
    type: "publicPageOwner",
    tooltip:
      "Если у тебя есть свои сообщества и ты хочешь начать зарабатывать на них, тогда регистрируйся как «Владелец групп». Зарабатывать можно и без групп, приглашая в сервис других администраторов сообществ с помощью своей реферальной ссылки.",
  },
  buttonSecondary: {
    title: "Рекламодателям",
    href: "https://bidfox.ru",
    type: "advertiser",
    tooltip:
      "Если у тебя цель запустить рекламную кампанию, познакомить аудиторию с новым продуктом или услугой, то тебе нужно регистрироваться как «Рекламодатель». В сервисе будет доступен функционал для автоматического размещения публикаций.",
  },
  Image: WelcomeImage,
  nameDownSection: SectionNamesVP.Monetization,
};

export const sliderContent: ISliderSection = {
  slogan: "Способы монетизации группы",
  title:
    "Мы постоянно тестируем разные способы монетизации паблика, прорабатываем различные модели сотрудничества с рекламодателями. Чтобы извлечь максимальную пользу, мы работаем с рекламодателями федерального покрытия и партнерскими программами, которые доставляют товары или оказывают услуги по всей России. Сейчас BidFox предлагает несколько способов монетизации групп с разными моделями оплаты:",
  sliderList: methodsMonetizations,
  getBackgroundCardColor: (id: number, isActive: boolean): string => {
    const cofficient = 0.97; // 0.5

    switch (id) {
      case 1:
        return `linear-gradient(148.62deg, rgba(42, 254, 183, ${
          isActive ? "1" : cofficient * 0.6
        }) 5.21%, rgba(8, 199, 188, ${
          isActive ? "1" : cofficient * 0.6
        }) 93.75%)`;
      case 2:
        return `linear-gradient(148.62deg, rgba(0, 173, 228, ${
          isActive ? "1" : cofficient
        }) 6.17%, rgba(4, 245, 237, ${isActive ? "1" : cofficient}) 94.27%)`;
      case 3:
        return `linear-gradient(148.62deg, rgba(193, 101, 221, ${
          isActive ? "1" : cofficient
        }) 6.02%, rgba(92, 39, 254, ${isActive ? "1" : cofficient}) 94.37%)`;
      case 4:
        return `linear-gradient(148.62deg, rgba(85, 175, 241, ${
          isActive ? "1" : cofficient
        }) 5.21%, rgba(17, 83, 252, ${isActive ? "1" : cofficient}) 95.31%)`;
      case 5:
        return `linear-gradient(148.62deg, rgba(29, 229, 226, ${
          isActive ? "1" : cofficient * 0.8
        }) 6.25%, rgba(181, 136, 247, ${
          isActive ? "1" : cofficient * 0.8
        }) 94.27%)`;
      case 6:
        return `linear-gradient(148.62deg, rgba(250, 205, 104, ${
          isActive ? "1" : cofficient * 0.7
        }) 5.73%, rgba(252, 118, 179, ${
          isActive ? "1" : cofficient * 0.7
        }) 93.75%)`;
      default:
        return `linear-gradient(148.62deg, rgba(42, 254, 183, ${
          isActive ? "1" : cofficient * 0.6
        }) 5.21%, rgba(8, 199, 188, ${
          isActive ? "1" : cofficient * 0.6
        }) 93.75%)`;
    }
  },
};
