import styled from "styled-components";

export const Buttons = styled.div`
  display: flex;
  justify-content: center;

  & button {
    width: 50%;
    justify-content: center;
    margin-right: 10px;

    &:first-child {
      padding-top: 13px;
      padding-bottom: 13px;
    }

    &:last-child {
      margin-right: 0;
      padding-top: 11px;
      padding-bottom: 11px;
    }

    @media ${(props) => props.theme.device["desktop"]},
      @media ${(props) => props.theme.device["desktop-medium"]} {
      margin-right: 20px;
    }
  }
`;

export const Agree = styled.div`
  label {
    display: flex;
    align-items: center;
    &:first-of-type {
      margin-bottom: 15px;
    }
  }
  & div {
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    color: ${(props) => props.theme.colors.text.contrast};
  }

  & button {
    width: auto;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
  }
`;
