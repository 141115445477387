import React, { useEffect } from "react";

import Form from "../../Form";

import { useForm } from "react-hook-form";

import * as Styles from "./RegisterConfirmation.styled";
import { requiredValidation } from "../../Form/utils/validation";
import storeApp, { TErrors } from "../../../../store/AppStore";
import Preloader from "../../../preloader";
import { observer } from "mobx-react-lite";

export interface IRegisterConfirmationSubmitData {
  code: string;
}

interface IRegisterConfirmationFormProps {
  cbSubmitAction: (data: IRegisterConfirmationSubmitData) => void;
  cbCancelAction?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  backendErrors?: TErrors;
}

interface IRegisterConfirmationFormInitialInputs {
  code: string;
}

enum RegisterConfirmationFormInputNames {
  code = "code",
}

const RegisterConfirmation: React.FC<IRegisterConfirmationFormProps> = ({
  cbCancelAction,
  cbSubmitAction,
  backendErrors,
}) => {
  const {
    register,
    control,
    handleSubmit,
    setError,
    getValues,
    reset,
    formState: { errors },
  } = useForm<IRegisterConfirmationFormInitialInputs>({
    mode: "onChange",
    defaultValues: {
      [RegisterConfirmationFormInputNames.code]: "",
    },
  });

  useEffect(() => {
    if (backendErrors) {
      const errors = Object.entries(backendErrors) as [
        [keyof typeof RegisterConfirmationFormInputNames, string]
      ];
      errors.forEach(([name, value]) => {
        if (value) {
          setError(name, {
            type: "backend",
            message: value,
          });
        }
      });
    }
  }, [backendErrors]);

  return (
    <Form onSubmit={handleSubmit(cbSubmitAction)}>
      {storeApp.isLoading && (
        <Preloader
          className="form-enter__preloader--top-20"
          text={"Загрузка..."}
        />
      )}
      {!storeApp.isLoading && (
        <Form.Field
          isError={Boolean(
            errors[RegisterConfirmationFormInputNames.code]?.message
          )}
        >
          <Form.Input
            name={RegisterConfirmationFormInputNames.code}
            register={register}
            placeholder="Введите код из SMS"
            rules={requiredValidation}
          />
          <Form.Label>Введите код из SMS</Form.Label>
          {errors && errors[RegisterConfirmationFormInputNames.code] && (
            <Form.Error>
              {errors[RegisterConfirmationFormInputNames.code]?.message}
            </Form.Error>
          )}
        </Form.Field>
      )}

      <Form.Field>
        <Styles.Buttons>
          <Form.SubmitButton>Регистрация</Form.SubmitButton>
          <Form.CancelButton onClick={cbCancelAction}>Назад</Form.CancelButton>
        </Styles.Buttons>
      </Form.Field>
    </Form>
  );
};

export default observer(RegisterConfirmation);
